const reals = [
    {
        title: "DokiTek Sigma",
        img: ['reals/gaiyo-1.jpeg', 'reals/gaiyo-2.jpeg', 'reals/gaiyo-3.jpeg'],
        pretext: 'Application de gestion de patientelle',
        link: 'http://dokitek.fr',
        listItems: [{
            icon: "mdi-react",
            content: "BackOffice : React"
        },
        {
            icon: "mdi-nodejs",
            content: "Backend : Node.js + Express"
        },
        {
            icon: "mdi-language-typescript",
            content: "100% TypeScript"
        },
        {
            icon: "mdi-calendar-month-outline",
            content: "Gestion de patientelle et rendez-vous"
        },
        {
            icon: "mdi-console",
            content: "Intégration système bas-niveau (UART)"
        },
        {
            icon: 'mdi-aws',
            content: 'CI/CD et architecture AWS'
        }
        ]
    },
    {
        title: "Sim800L-Node",
        img: ['reals/sim-1.jpeg', 'reals/sim-2.jpeg', 'reals/sim-3.jpeg'],
        pretext: 'Couche d\'abstraction logicielle Modem GSM - Node',
        link: 'https://www.npmjs.com/package/sim800l-node',
        listItems: [{
            icon: "mdi-npm",
            content: "Package NPM"
        },
        {
            icon: "mdi-cog",
            content: "Retro-Engineering modems GSM"
        },
        {
            icon: "mdi-language-typescript",
            content: "100% TypeScript"
        },
        {
            icon: "mdi-tray-full",
            content: "Gestion asynchrone des évènements entrants"
        },
        {
            icon: "mdi-console",
            content: "Interface de communication par port série"
        },
        {
            icon: 'mdi-open-source-initiative',
            content: 'Open Source sous licence MIT'
        }
        ]
    },
    {
        title: "Application mobile de visagisme",
        img: ['reals/vis-1.jpeg', 'reals/vis-2.jpeg', 'reals/vis-3.jpeg'],
        pretext: 'Développement multiplateforme fullstack',
        listItems: [{
            icon: "mdi-cordova",
            content: "Application : Vue.js + Cordova"
        },
        {
            icon: "mdi-nodejs",
            content: "Backend : Node.js + Express, full TypeScript"
        },
        {
            icon: "mdi-code-braces",
            content: "Utilisations d'API logicielles natives"
        },
        {
            icon: "mdi-gitlab",
            content: "CI/CD GitLab sur VPS OVH"
        },
        {
            icon: "mdi-apple",
            content: "Gestion des betas TestFlight et Google Play Console"
        },
        {
            icon: 'mdi-wrench',
            content: 'Support à la mise en production'
        }
        ]
    },
    {
        title: "CouponAsso - Quercy Caussadais",
        img: ['reals/quercy-1.jpeg', 'reals/quercy-2.jpeg', 'reals/quercy-3.jpeg'],
        pretext: 'Développement d\'une application de génération de coupons associatifs',
        link: "https://couponasso-quercycaussadais.fr",
        listItems: [{
            icon: "mdi-vuejs",
            content: "Frontend : Vue.js"
        },
        {
            icon: "mdi-nodejs",
            content: "Backend : Node.js + Express, TypeScript"
        },
        {
            icon: "mdi-file-pdf-box",
            content: "Génération dynamique de PDF"
        },
        {
            icon: "mdi-file-delimited",
            content: "Manipulation de données et gestions des CSV"
        },
        {
            icon: "mdi-gitlab",
            content: "CI/CD GitLab sur VPS OVH"
        },
        {
            icon: 'mdi-shield-account',
            content: 'Backoffice sur mesure'
        }
        ]
    },
    {
        title: "Projet Open-Source IoT",
        img: ['reals/nodeyt/nodeyt.jpg', 'reals/nodeyt/vitrine.jpg', 'reals/nodeyt/ide.jpg', 'reals/nodeyt/howto.jpg'],
        pretext: 'Hardware / Software + Site vitrine',
        link: 'https://julienfdev.github.io/nodeYT-subscriber-counter',
        listItems: [{
            icon: "mdi-cog",
            content: "Hardware : ESP8266"
        },
        {
            icon: "mdi-infinity",
            content: "Programmation software : Arduino IDE"
        },
        {
            icon: "mdi-vuejs",
            content: "Vitrine : Vue.js + Bootstrap"
        },
        {
            icon: "mdi-wifi",
            content: "Connectivité WiFi"
        },
        {
            icon: "mdi-youtube-subscription",
            content: "Interface API YouTube"
        },
        {
            icon: 'mdi-lock',
            content: 'Sécurlisation SSL/HTTPS'
        }
        ]
    },
    {
        title: "Modélisation 3D",
        img: ['reals/3d/blender.jpg', 'reals/3d/pokeball.jpg', 'reals/3d/coffee.jpg'],
        pretext: '3D paramétrique ou Renders photoréalistes',
        listItems: [{
            icon: "mdi-printer-3d",
            content: "Modélisation artistique ou paramétrique"
        },
        {
            icon: "mdi-webpack",
            content: "Donnez vie à vos concepts"
        },
        {
            icon: "mdi-blender-software",
            content: "Logiciel Open-Source Blender 2.9"
        },

        {
            icon: "mdi-texture-box",
            content: "Textures professionnelles"
        },
        {
            icon: 'mdi-camera',
            content: 'Situations photoréalistes'
        },
        {
            icon: "mdi-folder-zip",
            content: "Livraison des natifs"
        }
        ]
    }
];

module.exports = reals;