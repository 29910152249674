<template>
  <v-container class="grey darken-3 mt-2 white--text" rounded>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h4 font-weight-light">Exemples de réalisation</h1>
      </v-col>
    </v-row>
    <v-divider dark width="66%"></v-divider>
    <v-row justify="space-around">
      <v-col
        cols="12"
        sm="8"
        md="6"
        xl="5"
        v-for="card in cards"
        :key="card.title"
      >
        <CarouselCard :card="card" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CarouselCard from "@/components/CarouselCard";
import cards from "@/js/reals.js";

export default {
  name: "Reals",
  data() {
    return {
      cards,
    };
  },
  components: {
    CarouselCard,
  },
  beforeMount() {
    window.document.title =
      "Développeur d'Applications à Toulouse - Réalisations";
  },
};
</script>

<style lang="scss" scoped>
</style>