<template>
    <v-card :outlined="outlined" height="100%" class="d-flex flex-column">
        <v-responsive aspect-ratio="1.778" @click="changeImage">
            <v-carousel height="100%" hide-delimiter-background :cycle="cycle" interval=6000>
                <v-carousel-item v-for="(image) in card.img" :key='image' eager>
                    <v-img eager :src="img(image)"></v-img>
                </v-carousel-item>
            </v-carousel>
        </v-responsive>
        <v-card-title class="pb-0 mt-2  flex-row-reverse flex-sm-row justify-end justify-sm-space-between">
            <h5 class='font-weight-light text-h5 mb-0'>{{card.title}}</h5>
            <div v-if="card.link">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div><a :href="card.link" target="_blank" :aria-label="card.title" class="align-self-end">
                                <v-icon large class="mr-3 mr-sm-0" color="secondary" dark v-bind="attrs" v-on="on">
                                    mdi-web
                                </v-icon>
                            </a></div>
                    </template>
                    <span>Découvrir</span>
                </v-tooltip>
            </div>
        </v-card-title>
        <v-card-text class="text-subtitle-1 ">
            {{card.pretext}}
        </v-card-text>
        <v-divider width="70%"></v-divider>
        <v-card-text class="align-self-end card__text flex-grow-1 d-flex flex-column text-justify">
            <v-list dense>
                <v-list-item v-for="item in card.listItems" :key="item.content">
                    <v-list-item-icon>
                        <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{item.content}}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "CarouselCard",
        props: {
            card: {
                type: Object,
                required: true
            },
            outlined: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                cycle: true
            }
        },
        methods: {
            img(image) {
                return require(`@/assets/img/cards/${image}`);
            },
            changeImage() {
                this.cycle = false;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>